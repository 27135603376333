<template>
  <ElDialog
    visible
    :width="$viewport.lt.md ? 'auto' : '700px'"
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="$emit('close', {})"
  >
    <div
      slot="title"
      class="px-4"
    >
      <h2 class="text-black font-21 mb-0 text-center">
        Connect your PMS
      </h2>
      <div
        class="col-12 pt-1 text-center text-secondary"
      >
        Enter the API information for your system.
        <a
          target="_blank"
          :href="`https://help.showdigs.com/kb/guide/en/pms-integration-101-WlfHYTK22C`"
        >Learn More </a>
      </div>
      <div class="d-flex text-center justify-content-center">
        <div class="col-12 col-md-10 mt-4">
          <ElSelect
            v-model="currentSourceType"
            value-key="name"
            size="large"
            placeholder="Select your system"
            @change="currentSourceTypeChanged"
          >
            <ElOption
              v-for="sourceType in sourceTypes"
              :key="sourceType.value"
              :value="sourceType.value"
              :label="sourceType.label"
            />
          </ElSelect>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="col-12 col-md-10 mt-4">
          <component
            :is="relevantComponent"
            :source-type="currentSourceType"
            @update-payload="payload => updatePayload(payload)"
          />
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="row justify-content-center px-4"
    >
      <div
        class="col-md-4 col-12"
      >
        <ElButton
          class="w-100"
          size="medium"
          @click="$emit('close', {})"
        >
          Cancel
        </ElButton>
      </div>
      <div
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :loading="uiFlags.isLoading"
          :disabled="uiFlags.isLoading || isDisabled"
          @click="connect"
        >
          Connect
        </ElButton>
      </div>
      <div
        v-if="uiFlags.isLoading"
        class="col-12 pt-4 text-center text-secondary"
      >
        Verifying your credentials and establishing a connection.  Please be patient as this may take up to 30 seconds.
      </div>
    </div>
  </ElDialog>
</template>

<script>

import { reactive, ref } from '@vue/composition-api';
import { BusinessSourceTypes, BusinessSourceTypesOptions } from '@/constants/BusinessSource';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import integrationsComponents from '@/components/settings/PMSIntegrations';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'PMSIntegrationSettingsDialog',
  components: { ...integrationsComponents },
  props: {},
  setup(props, context) {
    const uiFlags = reactive({
      isLoading: false,
    });
    const sourceTypes = reactive(BusinessSourceTypesOptions);
    const relevantComponent = ref(null);
    const integrationPayload = ref({});
    const currentSourceType = ref(null);
    const isDisabled = ref(true);
    return {
      uiFlags,
      sourceTypes,
      currentSourceType,
      currentSourceTypeChanged,
      relevantComponent,
      updatePayload,
      connect,
      isDisabled,
    };

    async function connect() {
      uiFlags.isLoading = true;
      try {
        const response = await context.root.$store.dispatch('BusinessSource/store', integrationPayload.value);

        if (integrationPayload.value.integration_type !== BusinessSourceTypes.RENTVINE) {
          // eslint-disable-next-line no-inner-declarations
          async function checkCredentials() {
            try {
              await context.root.$store.dispatch('BusinessSource/checkCredentials', {
                businessSourceId: response.id,
              })
                .then((result) => {
                  if (result) {
                    setTimeout(checkCredentials, 5000); // Set the delay to the next poll
                  } else {
                    uiFlags.isLoading = false;
                    showSuccessNotify(context, 'Pending connection');
                    context.emit('close', response);
                  }
                });
            } catch (error) {
              uiFlags.isLoading = false;
              showErrorNotify(context, error.message, { duration: 10000 });
            }
          }
          await checkCredentials();
        } else {
          uiFlags.isLoading = false;
          showSuccessNotify(context, 'Pending connection');
          context.emit('close', response);
        }
      } catch (error) {
        uiFlags.isLoading = false;
        showErrorNotify(context, error.message);
      }
    }

    function updatePayload(payload) {
      integrationPayload.value = payload;
    }

    function currentSourceTypeChanged() {
      isDisabled.value = currentSourceType.value === BusinessSourceTypes.APPFOLIO;
      relevantComponent.value = `${capitalizeFirstLatter(currentSourceType.value)}Integration`;
    }
  },
};
</script>
